<template>
        <!-- Footer Start -->
        <div class="container-fluid text-white-50 footer pt-5">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <router-link class="d-inline-block mb-3" to="/"><h1 class="text-white">Agence R<span class="text-primary">.</span>42</h1></router-link>
                    <p class="mb-0">Jeune agence digitale, spécialisée dans la création de sites web et la stratégie numérique, nous allions créativité et expertise technique pour garantir une expérience en ligne sur mesure.</p>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <h5 class="text-white mb-4">Contact</h5>
                    <p><font-awesome-icon class="me-3" :icon="['fas', 'location-dot']" />Région Parisienne</p>
                    <p><font-awesome-icon class="me-3" :icon="['fas', 'envelope']" />contact@reponse42.com</p>
                    <!--
                    <div class="d-flex pt-2">
                        <a class="btn btn-outline-light btn-social" href=""><font-awesome-icon :icon="['fab', 'twitter']" /></a>
                        <a class="btn btn-outline-light btn-social" href=""><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>
                        <a class="btn btn-outline-light btn-social" href=""><font-awesome-icon :icon="['fab', 'instagram']" /></a>
                        <a class="btn btn-outline-light btn-social" href=""><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a>
                    </div>
                    -->
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <h5 class="text-white mb-4">Liens populaires</h5>
                    <router-link class="btn btn-link" to="/expertise">Expertise</router-link>
                    <router-link class="btn btn-link" to="/apropos">A propos</router-link>
                    <router-link class="btn btn-link" to="/valeurs">Nos valeurs</router-link>
                    <router-link class="btn btn-link" to="/contact">Contact</router-link>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <h5 class="text-white mb-4">Nos offres</h5>
                    <router-link class="btn btn-link" to="/expertise">Conseil stratégie digitale</router-link>
                    <router-link class="btn btn-link" to="/expertise#anchor-dev">Création site web</router-link>
                    <router-link class="btn btn-link" to="/expertise#anchor-host">Hébergement et maintenance</router-link>
                    <router-link class="btn btn-link" to="/expertise#anchor-image">Séances photos et visuels</router-link>
                    <router-link class="btn btn-link" to="/expertise#anchor-gestion">Conseil & gestion de projet</router-link>
                </div>
            </div>
        </div>
        <div class="container wow fadeIn" data-wow-delay="0.1s">
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <router-link class="border-bottom" to="/">R42</router-link>, All Rights Reserved.
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <div class="footer-menu">
                            <router-link to="/">Accueil</router-link>
                            <router-link to="/cookies">Cookies</router-link>
                            <router-link to="/faq">FAQs</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer End -->
</template>

<style>
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}


/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

/*** Footer ***/
.footer {
    background: url(../assets/footer.png) center center no-repeat;
    background-size: contain;
    background-color: #14183E;
}

.footer .btn.btn-social {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255,255,255,.5);
    border: 1px solid rgba(256, 256, 256, .1);
    border-radius: 40px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: #1363C6;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
    color: rgba(255,255,255,.5);
    font-weight: normal;
    transition: .3s;
}

.footer .btn.btn-link:hover {
    color: #FFFFFF;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 14px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: rgba(255,255,255,.5);
}

.footer .copyright a:hover {
    color: #FFFFFF;
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}
</style>