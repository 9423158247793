<template>
    <div class="container-fluid m-2">
        <div class="row d-flex">
            <div class="col col-md-6 card d-flex m-auto">
                <h2 class="text-center mt-3">Votre Message</h2>
                <form @submit.prevent="submitForm">
                    <div class="my-3">
                        <input class="form-control" type="text" v-model="name" placeholder="Votre nom" required />
                    </div>
                    <div class="mb-3">
                        <input class="form-control" type="email" v-model="email" placeholder="Votre email" required />
                    </div>
                    <div class="mb-3">
                        <textarea class="form-control" v-model="message" placeholder="Votre message" required></textarea>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-primary mb-3" type="submit">Envoyer</button>
                    </div>
                </form>
                <p v-if="statusMessage">{{ statusMessage }}</p>
            </div>
        </div>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        name: '',
        email: '',
        message: '',
        contactMail: 'contact.r42@gmail.com', //à modifier en fonction de l'adresse du destinataire
        statusMessage: ''
      };
    },
    methods: {
      async submitForm() {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('message', this.message);
        formData.append('contactMail', this.contactMail);
  
        try {
          const response = await fetch('https://www.reponse42.com/send_email.php', {
            method: 'POST',
            body: formData
          });
          const result = await response.json();
          if (result.success) {
            this.statusMessage = 'Votre message a été envoyé avec succès!';
            this.name = '';
            this.email = '';
            this.message = '';
          } else {
            this.statusMessage = 'Une erreur s\'est produite, veuillez réessayer.';
          }
        } catch (error) {
          console.error('Error:', error);
          this.statusMessage = 'Une erreur s\'est produite, veuillez réessayer.';
        }
      }
    }
  };
  </script>
  
  <style>
  /* Ajoutez votre style ici */
  </style>
  